<template>
  <div>
    <form-section :title="$t('courierSet.payment')">
      <v-row>
        <v-col>
          <text-field
            v-model="getDefaultValues.mpk"
            :title="$t('courierSet.costsCenter')"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getOrganization']),
    getDefaultValues() {
      return this.getConfigurationSet().ambroExpressDefaultValues;
    },
  },
};
</script>

<style scoped></style>
