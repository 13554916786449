export const AmbroExpressDefaultValues = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  insuranceValue: 0,
  contentDescription: '',
  mpk: '',
  referenceNumber: '',
  retAccountNo: '',
  additionalServices: [],
};
