import { AmbroExpressDefaultValues } from './AmbroExpressDefaultValues';

export const AmbroExpress = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  login: '',
  password: '',
  courierCode: 'ambroExpress',
  integration: 'ambroExpress',
  skipShipmentGeneration: true,
  requireLabel: true,
  labelSource: 'NUBOWMS',
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
  urlAddress: '',
  serviceId: 'Standard',
  labelFormat: 1,
  ambroExpressDefaultValues: AmbroExpressDefaultValues,
};
