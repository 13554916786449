export const ambroExpressAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: '',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki.',
    parameters: true,
    remark: '',
  },
  {
    id: 4,
    serviceId: 'ROD',
    name: 'Zwrot dokumentów',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 207,
    serviceId: 'SMS',
    name: 'Serwis SMS',
    description: 'Powiadomienie o przesyłce via SMS.',
    parameters: false,
    remark: '',
  },
  {
    id: 206,
    serviceId: 'EMAIL',
    name: 'Serwis e-mail',
    description: 'Powiadomienie o przesyłce via e-mail.',
    parameters: false,
    remark: '',
  },
  {
    id: 403,
    serviceId: 'BRINGING_UPSTAIRS',
    name: 'Wniesienie',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 406,
    serviceId: 'INSTALLATION',
    name: 'Montaż',
    description: '',
    parameters: false,
    remark: '',
  },
];
