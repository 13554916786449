<template>
  <div>
    <form-section :title="$t('courierSet.prcelContent')">
      <v-row>
        <v-col>
          <text-area-field
            v-model="getDefaultValues.contentDescription"
            :title="$t('courierSet.parcelContent')"
            rules="required|max:165|min:3"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getDefaultValues.referenceNumber"
            :title="$t('courierSet.packageReferenceNumber')"
            rules=""
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getDefaultValues() {
      return this.getConfigurationSet().ambroExpressDefaultValues;
    },
  },
};
</script>

<style scoped></style>
